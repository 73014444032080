@import url(https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400;700;900&display=swap);
body {
    padding: 0;
    margin: 0;
    font-family: 'Lato', sans-serif;
}

/* Scrollbar Styling */
/* ::-webkit-scrollbar {
    width: 5px;
}

::-webkit-scrollbar-track {
    background-color: #eeeded;
    -webkit-border-radius: 5px;
    border-radius: 5px;
}

::-webkit-scrollbar-thumb {
    -webkit-border-radius: 5px;
    border-radius: 5px;
    background: #eeeded;
} */

/* ---------------------- Common CSS Section Start ---------------------- */
:root {
    --whiteColor: #ffffff;
    --greenColor: #1DBF73;
    --greenColorThree: #05BB63;
    --darkGreen: #018445;
    --navColor: #292A2F;
    --darkOne: #202226;
    --paraGray: #464646;
    --yellowColor: #FFB602;
    --redColor: #FF3103;
    --headingColor: #182F46;
    --grayOne: #767676;
    --grayTwo: #9C9B9B;
    --offWhite: #F7F7F2;
    --footerBg: #26292E;
}

.light-violet-bg {
    background-color: #F3F2FF;
}

.light-magenta-bg {
    background-color: #FBEDF3;
}

.light-green-bg {
    background-color: #ECFAF7;
}

.light-pink-bg {
    background-color: #FAF0FF;
}

.violet-bg {
    background-color: #6560FF;
}

.magenta-bg {
    background-color: #C71F66;
}

.green-bg {
    background-color: #0FC393;
}

.pink-bg {
    background-color: #BE3EFF;
}

.heading {
    text-align: center;
}

h2 {
    font-size: 36px;
}

h3 {
    font-size: 32px;
}

h4 {
    font-size: 20px;
}

h5 {
    font-size: 18px;
}

h6 {
    font-size: 14px;
    font-weight: 600;
}

a {
    text-decoration: none;
    color: #1DBF73;
    color: var(--greenColor);
}

a:hover {
    color: #fff;
    text-decoration: none;
}

textarea{
    resize: none;
}

.large-btn {
    width: 126px;
    height: 45px;
    background-color: #1DBF73;
    background-color: var(--greenColor);
    color: #ffffff;
    color: var(--whiteColor);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    text-decoration: none;
}

.small-btn {
    width: 104px;
    height: 37px;
    background-color: #1DBF73;
    background-color: var(--greenColor);
    color: #ffffff;
    color: var(--whiteColor);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    text-decoration: none;
}

.section-sub-title {
    padding-top: 10px;
}

.section-sub-title span {
    padding-top: 10px;
    color: red;
    font-weight: 700;
    /* text-decoration: underline; */
}


.card-design {
    width: 100%;
    min-height: 362px;
    background-color: #ffffff;
    background-color: var(--whiteColor);
    border-radius: 10px;
    overflow: hidden;
    margin: 15px auto;
    box-shadow: 0px 0px 13px -3px rgba(0, 0, 0, 0.63);
}

.card-design .card-img-box {
    width: 100%;
    height: 204px;
}

.card-design .card-img-box img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.card-design .card-info {
    padding: 20px;
}

.card-design .card-info h5 {
    font-weight: 700;
    line-height: 22px;
    color: #182F46;
    color: var(--headingColor);
}

.card-design .card-info .card-rating {
    font-size: 14px;
    font-weight: 800;
    color: #464646;
    color: var(--paraGray);
    margin-bottom: 10px;
}

.card-design .card-info .card-rating i {
    color: #FF3103;
    color: var(--redColor);
}

.card-design .card-info p {
    font-size: 14px;
    color: #464646;
    color: var(--paraGray);
    margin-bottom: 10px;
}

.card-footer {
    border-top: none;
    padding: 0;
    /* background-color: #FFB602; */
}

.card-design .card-info .card-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #fff;
}

.pro-card-price {
    font-size: 13px !important;
    color: rgb(122, 24, 24);
    padding-top: 5px;
}

.card-design .card-info .card-footer .card-price {
    font-size: 15px;
    font-weight: 700;
    color: #464646;
    color: var(--paraGray);
}

.card-design .card-info .card-footer .card-price span {
    font-size: 20px;
    font-weight: 900;
    color: #FF3103;
    color: var(--redColor);
    display: block;
}

/* ---------------------- Common CSS Section End ---------------------- */


/* ---------------------- Loading Section CSS Start ---------------------- */
.loading {
    width: 100%;
    height: 100%;
    position: fixed;
    inset: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999;
}

@-webkit-keyframes loading-item {
    0% {
        transform: rotate(0deg)
    }

    50% {
        transform: rotate(180deg)
    }

    100% {
        transform: rotate(360deg)
    }
}

@keyframes loading-item {
    0% {
        transform: rotate(0deg)
    }

    50% {
        transform: rotate(180deg)
    }

    100% {
        transform: rotate(360deg)
    }
}

.loading-item div {
    position: absolute;
    -webkit-animation: loading-item 1s linear infinite;
            animation: loading-item 1s linear infinite;
    width: 128px;
    height: 128px;
    top: 36px;
    left: 36px;
    border-radius: 50%;
    box-shadow: 0 6.4px 0 0 #1DBF73;
    transform-origin: 64px 67.2px;
}

.loading-inner {
    width: 200px;
    height: 200px;
    display: inline-block;
    overflow: hidden;
}

.loading-item {
    width: 100%;
    height: 100%;
    position: relative;
    transform: translateZ(0) scale(1);
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
    transform-origin: 0 0;
}

.loading-item div {
    box-sizing: content-box;
}

/* ---------------------- Loading Section CSS End ---------------------- */


/* ---------------------- Header Section CSS Start ---------------------- */
header {
    width: 100%;
    margin: 0px auto;
}

.custom-navbar {
    width: 90%;
    height: 66px;
    padding: 10px 40px;
    background: rgba(255, 255, 255, 0.77);
    border-radius: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    top: 35px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 999;
    transition: .8s;
}

.custom-navbar.active {
    width: 100%;
    background: rgb(255, 255, 255);
    box-shadow: 0px 10px 25px -7px rgba(0, 0, 0, 0.1);
    border-radius: 0;
    top: 0px;
    z-index: 999;
    transition: .8s;
}

.custom-navbar .brand a {
    text-decoration: none;
    font-weight: 900;
    font-size: 32px;
    line-height: 38px;
    color: #1DBF73;
    color: var(--greenColor);
}

.custom-navbar nav ul {
    padding: 0;
    margin: 0;
    list-style: none;
}

.custom-navbar nav ul li {
    display: inline-block;
    padding-inline: 20px;
}

.custom-navbar nav ul li a {
    text-decoration: none;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    color: #292A2F;
    color: var(--navColor);
}

.custom-navbar .nav-link-btn {
    display: flex;
    justify-content: right;
    align-items: center;
}

.goog-te-banner-frame {
    top: auto;
    top: initial;
    bottom: 0;
    display: none;
}

.custom-navbar .join-as {
    /* width: 228px; */
    width: 250px;
    height: 46px;
    background-color: #1DBF73;
    background-color: var(--greenColor);
    border-radius: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    margin: 0px 20px 0 5px;
    text-align: center;
}

.custom-navbar .join-as a {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    text-decoration: none;
    color: #ffffff;
    color: var(--whiteColor);
}

.custom-navbar .nav-link-btn .login-btn a {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    text-decoration: none;
    color: #292A2F;
    color: var(--navColor);
    padding-left: 20px;
    position: relative;
}

.custom-navbar .nav-link-btn .login-btn a span {
    display: inline-block;
    width: 25px;
    height: 25px;
    background-color: #05bb63;
    border-radius: 50%;
    position: absolute;
    top: -15px;
    right: -20px;
    text-align: center;
    font-size: 13px;
    color: #ffffff;
    font-weight: 600;
    line-height: 24px;
}

.custom-navbar .nav-btn {
    display: none;
    font-size: 28px;
    color: #018445;
    color: var(--darkGreen);
}

.sign-para a {
    color: #fff;
}

/* ---------------------- Header Section CSS End ---------------------- */

/* ---------------------- Banner Section CSS Start ---------------------- */
.breadcrumb {
    background-color: #E1FBF9 !important;
}

.banner {
    background-color: #26292E;
    position: relative;
    padding-top: 130px;
    z-index: 0;
    padding-bottom: 40px;
}

.banner-inner {
    background-image: none !important;
    height: 200px;
    background-color: #E1FBF9;
    display: flex;
    align-items: flex-end;
    padding-bottom: 30px;
}

.banner-content {
    text-align: left;
}

.banner .banner-content h3 {
    font-weight: 900;
    line-height: 50px;
    color: #ffffff;
    color: var(--whiteColor);
}

.banner .banner-content .banner-head {
    font-size: 55px;
    font-weight: 900;
    color: #ffffff;
    color: var(--whiteColor);
    margin-bottom: 20px;
}

.banner .banner-content .banner-head span {
    width: 279px;
    background-color: #1DBF73;
    background-color: var(--greenColor);
    font-weight: 800;
    border-radius: 9px;
    padding: 5px 15px;
}

.banner-slider-wrap {
    width: 100%;
    border-radius: 15px;
    overflow: hidden;
    margin-left: 30px;
}

/* Admin Panel Start*/

.banner-provider-panel {
    background-color: #e5e2e2;
    position: relative;
    padding-top: 130px;
    z-index: 0;
    padding-bottom: 40px;
}

.banner-provider-panel .banner-content h3 {
    font-weight: 900;
    line-height: 50px;
    color: #182F46;
}

.banner-provider-panel .banner-content .banner-head {
    font-size: 55px;
    font-weight: 900;
    color: #182F46;
    margin-bottom: 20px;
}

.banner-provider-panel .banner-content .banner-head span {
    width: 279px;
    background-color: #1DBF73;
    background-color: var(--greenColor);
    color: #fff;
    font-weight: 800;
    border-radius: 9px;
    padding: 5px 15px;
}

.banner-provider-link {
    margin-top: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.banner-provider-link .banner-btn {
    width: 230px;
    height: 43px;
    border: 1px solid #666;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-inline: 8px;
    text-decoration: none;
    font-size: 15px;
    font-weight: 600;
    color: #182F46;
    position: relative;
    z-index: 1;
    transition: all 0.5s;
}

/* Admin anel End */


/* Search Bar Start */
.form-wrapper {
    width: 100%;
    height: 49px;
    background-color: #ffffff;
    background-color: var(--whiteColor);
    border-radius: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    border: 1px solid #d5d5d5;
}

/* .form-wrapper-hide{
    display: flex!important;
} */

.form-wrapper input {
    padding: 10px 20px;
    height: 80%;
    border: none;
    outline: none;
}

.form-wrapper .search-service {
    width: 100%;
    /* border-right: 1px solid var(--grayTwo); */
}

.form-wrapper .search-btn {
    width: 54px;
    height: 100%;
    font-size: 22px;
    background-color: #018445;
    background-color: var(--darkGreen);
    color: #ffffff;
    color: var(--whiteColor);
    border: none;
    outline: none;
}

.search-holder-wrap {
    width: 100%;
    height: 300px;
    position: absolute;
    left: 0;
    top: 50px;
    overflow-y: scroll;
    overflow-x: hidden;
    background-color: #ffffff;
    z-index: 99;
    padding: 15px;
    box-sizing: border-box;
    border: 1px solid #eee;
    box-shadow: 0px 4px 6px #b7b7b7;
}

.search-holder-wrap h6 {
    font-size: 14px;
    color: #818181;
}

.hide-search-holder-wrap {
    width: 100%;
    height: 300px;
    position: absolute;
    left: 0;
    top: 50px;
    overflow-y: scroll;
    overflow-x: hidden;
    background-color: #ffffff;
    z-index: 99;
    padding: 15px;
    box-sizing: border-box;
    border: 1px solid #eee;
    box-shadow: 0px 4px 6px #b7b7b7;
    display: none;
}

.clear-all {
    position: absolute;
    top: 10px;
    right: 20px;
}

.search-reasult-wrap {
    width: 100%;
    margin-right: 15px;
    justify-content: space-between;
    padding-bottom: 5px;
    border-bottom: 1px solid #e3e3e3;
    margin-bottom: 10px;
}

.search-reasult-service {
    width: 75%;
    justify-content: space-between;
    padding-top: 3px;
}

.search-reasult-service h4,
.search-reasult-service h5 {
    font-size: 13px;
    color: #05BB64;
    font-weight: 500;
    line-height: 29px;
    margin: 0px;
}

.search-reasult-service h5 {
    color: #182F46;
}

.search-reasult-wrap:last-child {
    border: none;
}

.search-service-btn {
    display: inline-block;
    width: 27%;
    border: 1px solid #05BB64;
    font-size: 12px;
    border-radius: 5px;
    text-align: center;
    padding: 4px;
    line-height: 25px;
}

.btn_selected {
    color: #fff;
    background-color: #05BB64;
}

.search-service-btn:hover {
    color: #fff;
    background-color: #05BB64;
    transition: 0.3s;
}

/* Search Bar End */

.banner-link {
    margin-top: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.banner-link .popular-services {
    color: #ffffff;
    color: var(--whiteColor);
    font-size: 16px;
    font-weight: 600;
}

.banner-link .banner-btn {
    width: 230px;
    height: 43px;
    border: 1px solid #1DBF73;
    border: 1px solid var(--greenColor);
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-inline: 8px;
    text-decoration: none;
    font-size: 15px;
    font-weight: 600;
    color: #ffffff;
    color: var(--whiteColor);
    position: relative;
    z-index: 1;
    transition: all 0.5s;
}

.banner-link .banner-btn:hover {
    transition: all 0.5s;
}

.banner-link .banner-btn::before {
    content: "";
    width: 100%;
    height: 100%;
    background: linear-gradient(0deg, rgba(29, 191, 115, 1) 24%, rgba(249, 249, 249, 0) 100%);
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: -1;
    transform: scaleY(0);
    transform-origin: bottom;
    transition: all 0.5s;
}

.banner-link .banner-btn::before:hover {
    transform-origin: top;
}

.banner-link .banner-btn:hover::before {
    transform: scaleY(1);
    transition: all 0.5s;
}



/* ---------------------- Banner Section CSS End ---------------------- */

/* ---------------------- Services Section CSS Start ---------------------- */
#services {
    padding: 50px 0;
}

#services .services-info h3 {
    color: #05BB63;
    color: var(--greenColorThree);
    font-weight: 600;
    line-height: 28px;
}

#services .services-info h2 {
    color: #182F46;
    color: var(--headingColor);
    font-weight: 800;
    line-height: 60px;
}

#services .services-info p {
    width: 75%;
    color: #464646;
    color: var(--paraGray);
    font-size: 15px;
    font-weight: 500;
    line-height: 22px;
}

#services .services-info a {
    text-decoration: none;
    color: #ffffff;
    color: var(--whiteColor);
}

.service-item {
    width: 100%;
    text-align: center;
    margin-bottom: 20px;
}


.service-item .service-icon {
    max-width: 92px;
    ;
    min-height: 92px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
    background: radial-gradient(circle at 100% 100%, #ffffff 0, #ffffff 5px, transparent 5px) 0% 0%/8px 8px no-repeat,
        radial-gradient(circle at 0 100%, #ffffff 0, #ffffff 5px, transparent 5px) 100% 0%/8px 8px no-repeat,
        radial-gradient(circle at 100% 0, #ffffff 0, #ffffff 5px, transparent 5px) 0% 100%/8px 8px no-repeat,
        radial-gradient(circle at 0 0, #ffffff 0, #ffffff 5px, transparent 5px) 100% 100%/8px 8px no-repeat,
        linear-gradient(#ffffff, #ffffff) 50% 50%/calc(100% - 6px) calc(100% - 16px) no-repeat,
        linear-gradient(#ffffff, #ffffff) 50% 50%/calc(100% - 16px) calc(100% - 6px) no-repeat,
        linear-gradient(55deg, #05bb63 0%, #ffffff 67%);
    border-radius: 8px;
    padding: 3px;
    box-sizing: content-box;
}

.service-item .service-icon:hover {
    box-shadow: -5px 24px 21px -20px rgba(0, 0, 0, 0.26);
}

.service-title h6 {
    font-weight: 600;
    line-height: 22px;
    color: #182F46;
    color: var(--headingColor);
    font-size: 16px;
}

/* ---------------------- Services Section CSS End ---------------------- */

/* ---------------------- Best-Offer Section CSS Start ---------------------- */
#offer {
    padding: 50px 0;
    background-color: #1DBF73;
    background-color: var(--greenColor);
}

#offer .heading h3 {
    color: #ffffff;
    color: var(--whiteColor);
    font-weight: 900;
    line-height: 22px;
}

#offer .heading h3 span {
    color: #182F46;
    color: var(--headingColor);
}

/* ---------------------- Best-Offer Section CSS End ---------------------- */

/* ---------------------- Popular Services Section CSS Start ---------------------- */
#popular-services {
    padding: 50px 0;
    background-color: #1DBF73;
    background-color: var(--greenColor);
}

#popular-services .heading {
    margin-bottom: 40px;
}

#popular-services .heading h3 {
    /* color: var(--darkGreen); */
    color: #ffffff;
    color: var(--whiteColor);
    font-weight: 900;
    line-height: 22px;
}

#popular-services .heading h3 span {
    color: #182F46;
    color: var(--headingColor);
}

.popular-services {
    color: #fff;
    line-height: 30px;
    font-weight: 800;
    padding-left: 10px;
}

.popular-provider-services {
    color: #182F46;
    line-height: 30px;
    font-weight: 800;
    padding-left: 10px;
}

/* ---------------------- Popular Services Section CSS End ---------------------- */

/* ---------------------- Marketplace Section CSS Start ---------------------- */
#marketplace {
    align-items: center;
    padding-bottom: 50px;
}

.marketplace-info {
    width: 60%;
    margin: 0 auto;
}

.marketplace-info h2 {
    font-weight: 700;
}

.commitment-box {
    width: 100%;
    padding: 40px 20px;
    border-radius: 10px;
    text-align: center;
    box-sizing: border-box;
    margin: 20px auto;
    transition: all 0.5s;
}

.commitment-box:hover {
    transform: scale(1.1);
    transition: all 0.5s;
}

.commitment-box .commitment-icon {
    width: 70px;
    height: 70px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
}

.commitment-box .commitment-info {
    margin-top: 15px;
}

.commitment-box .commitment-info .title {
    font-size: 20px;
    font-weight: 700;
    color: #182F46;
    color: var(--headingColor);
}

.commitment-box .commitment-info p {
    font-size: 15px;
    font-weight: 500;
    color: #182F46;
    color: var(--headingColor);
}

/* ---------------------- Marketplace Section CSS End ---------------------- */

/* ---------------------- Service Slider Section CSS Start ---------------------- */
#service-slider {
    padding: 0 0 50px 0;
}

#service-slider .slider-item {
    width: 100%;
    height: 100%;
    border-radius: 3px;
    /* overflow: hidden;  */
    position: relative;
    margin-left: 20px;
}


#service-slider .slider-item .img-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    opacity: 0;
    transition: opacity 0.50s;
}

#service-slider .slider-item .img-overlay:hover {
    opacity: 1;
}

#service-slider .slider-item .img-overlay>* {
    transform: translateY(40px);
    transition: transform 0.50s;
}

#service-slider .slider-item .img-overlay:hover>* {
    transform: translateY(0);
}

#service-slider .slider-item .img-overlay .slider-title {
    font-size: 22px;
    font-weight: 700;
    color: #ffffff;
    color: var(--whiteColor);
}

#service-slider .swiper {
    width: 100%;
    height: 100%;
}

#service-slider .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
}

#service-slider .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

#service-slider .swiper {
    width: 100%;
    height: 300px;
    margin: 20px auto;
}

.swiper-pagination {
    bottom: -20px;
}

/* ---------------------- Service Slider Section CSS End ---------------------- */

/* ---------------------- Testimonials Section CSS Start ---------------------- */
#testimonials {
    background-color: #F7F7F2;
    background-color: var(--offWhite);
    padding: 50px 0;
}

#testimonials .testimonials-heading {
    text-align: left;
    margin-bottom: 50px;
}

#testimonials .testimonials-heading h4 {
    font-weight: 700;
    color: #1DBF73;
    color: var(--greenColor);
}

#testimonials .testimonials-heading h2 {
    font-weight: 800;
    color: #182F46;
    color: var(--headingColor);
}

#testimonials .testimonial-box {
    width: 97%;
    min-height: 300px;
    background-color: #ffffff;
    background-color: var(--whiteColor);
    box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.15);
    padding: 20px;
    margin: 0 auto 20px auto;
    border-radius: 13px;
}

#testimonials .testimonial-box .rating {
    color: #FFB602;
    color: var(--yellowColor);
    font-size: 15px;
    margin-bottom: 20px;
}

#testimonials .testimonial-box .feedback {
    font-size: 15px;
    font-weight: 500;
    color: #464646;
    color: var(--paraGray);
}

#testimonials .testimonial-box .person {
    font-size: 16px;
    font-weight: 600;
    color: #333;
}

.slide-testimonials,
.mySwiper {
    padding-bottom: 0px;
}

.swiper-pagination-bullet-active {
    /*background-color: var(--greenColor);*/
    display: none;
}

.testimonial-box .testi-client img {
    width: 100px !important;
}

/* ---------------------- Testimonials Section CSS End ---------------------- */

/*--------- Booking History/Wish-List/Select-Provider CSS Start ------------*/

#provider-panel {
    padding: 15px 0;
}


#wish-list,
#select-providers,
#booking-history {
    padding: 15px 0;
}

/*--------- Booking History/Wish-List/Select-Provider  CSS End --------------*/

/*------------------------ Contact Page CSS Start ---------------------------*/
.contact-wrap {
    width: 100%;
    padding: 50px 0;
}

.onboardForm {
    width: 100%;
}

.onboardForm input,
textarea {
    margin-bottom: 10px;
}

.map-wrap {
    width: 100%;
}

.map-area {
    width: 100%;
    min-height: 300px;
}

/* ----------------------- Contact Page CSS End ----------------------------*/

/* ---------------------- Footer Section CSS Start ---------------------- */
footer {
    background-color: #26292E;
    background-color: var(--footerBg);
    padding: 50px 0;
}

footer .footer-brand a {
    text-decoration: none;
    font-weight: 900;
    font-size: 32px;
    line-height: 38px;
    color: #ffffff;
    color: var(--whiteColor);
}

footer .about-company {
    font-weight: 500;
    font-size: 15px;
    color: #ffffff;
    color: var(--whiteColor);
    margin-block: 20px;
}

footer form {
    margin-top: 20px;
    display: inline-block;
}

footer form .form-area {
    width: 100%;
    height: 38px;
    background-color: #ffffff;
    background-color: var(--whiteColor);
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 3px;
    overflow: hidden;
}

footer form .form-area input {
    padding: 0 10px;
    border: none;
    outline: none;
}

footer form .form-area .send-btn {
    width: 59px;
    height: 100%;
    background-color: #1DBF73;
    background-color: var(--greenColor);
    color: #ffffff;
    color: var(--whiteColor);
    border: none;
    outline: none;
}

footer h4 {
    color: #ffffff;
    color: var(--whiteColor);
    font-weight: 500;
    position: relative;
    font-size: 20px;
}

footer h4::after {
    content: "";
    width: 43px;
    height: 1px;
    background-color: #ffffff;
    background-color: var(--whiteColor);
    position: absolute;
    left: 0;
    bottom: -5px;
}

footer .footer-list,
footer .footer-contact {
    padding: 0;
    margin: 0;
    list-style: none;
    margin-top: 20px;
}

footer .footer-list li {
    padding-block: 5px;
}

footer .footer-list li a {
    text-decoration: none;
    color: #9C9B9B;
    color: var(--grayTwo);
    font-size: 13px;
    font-weight: 500;
}

footer .footer-list li i {
    color: #1DBF73;
    color: var(--greenColor);
    font-size: 18px;
}

footer .footer-contact li {
    padding-block: 5px;
    color: #ffffff;
    color: var(--whiteColor);
}

footer .footer-contact li a {
    text-decoration: none;
    color: #ffffff;
    color: var(--whiteColor);
    font-size: 16px;
    font-weight: 500;
}

footer .footer-contact li i {
    padding-right: 10px;
    font-size: 20px;
}

footer .social-site {
    display: flex;
    justify-content: left;
    align-items: center;
    margin-block: 20px;

}

footer .social-site a {
    width: 40px;
    height: 40px;
    border: 1px solid #ffffff;
    border: 1px solid var(--whiteColor);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 15px;
    text-decoration: none;
    font-size: 20px;
    color: #ffffff;
    color: var(--whiteColor);
    position: relative;
    transition: all 0.5s;
    z-index: 1;
}

footer .social-site a:hover {
    color: #26292E;
    color: var(--footerBg);
    transition: all 0.5s;
}

footer .social-site a::before {
    content: "";
    width: 100%;
    height: 100%;
    background-color: #F7F7F2;
    background-color: var(--offWhite);
    border-radius: 50%;
    position: absolute;
    top: 0;
    left: 0;
    transform: scale(0);
    transition: all 0.5s;
    z-index: -1;
}

footer .social-site a:hover::before {
    transform: scale(1);
    transition: all 0.5s;
}



.scroll-top {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #1DBF73;
    background-color: var(--greenColor);
    box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.36);
    position: fixed;
    right: 20px;
    bottom: 20px;
    z-index: 9999;
}

.scroll-top img {
    height: 30px;
}

/* ---------------------- Footer Section CSS End ---------------------- */

/* ---------------------- Legal Section CSS Start ---------------------- */
.header-top-sec {
    background-color: #202226;
    background-color: var(--darkOne);
    padding: 5px 0;
    text-align: right;
}

.header-top-sec p {
    color: #767676;
    color: var(--grayOne);
    margin: 0px;
    font-size: 14px;
}

#legal {
    background-color: #202226;
    background-color: var(--darkOne);
    padding: 15px 0;
    text-align: center;
    position: fixed;
    bottom: 0;
    width: 100%;
    /* margin-top: 10px; */
    z-index: 7;
}

#legal p {
    color: #767676;
    color: var(--grayOne);
    margin: 0px;
    font-size: 14px;
}


/* ---------------------- Legal Section CSS End ---------------------- */


/* ---------------------- User registration page start ---------------------- */
.inner-banner-headings {
    font-size: 24px;
    font-weight: 600;
    color: #182F46;
    color: var(--headingColor);
}

.user-reg-sec {
    width: 100%;
    padding: 50px 0px;
}

.user-reg-frm-wrap {
    width: 80%;
    background-color: #1A8656;
    padding: 30px;
    border-radius: 10px;
    margin: 0 auto;
}

.form-floating {
    width: 100%;
}

.form-control:focus {
    box-shadow: none;
    border-color: #1dbf73
}

.form-select:focus {
    box-shadow: none;
    border-color: #1dbf73
}

.btn-main {
    display: inline-block;
    background-color: #FFB602;
    background-color: var(--yellowColor);
    color: #333;
    padding: 12px 10px;
    border-radius: 4px;
}

.btn-load-slot-data {
    display: block;
    width: 100%;
    background-color: #FFB602;
    background-color: var(--yellowColor);
    color: #333;
    padding: 7px 0px;
    border-radius: 6px;
    border: none;
    font-weight: 600;
}

.btn-submit,
.btn-pass {
    display: block;
    width: 100%;
    background-color: #FFB602;
    background-color: var(--yellowColor);
    color: #333;
    padding: 12px 0px;
    border-radius: 6px;
    border: none;
    font-weight: 600;
}

.btn-pass {
    background-color: transparent;
    border: 1px solid #fff;
    color: #fff;
}

.user-reg-frm-wrap h4 {
    color: #fff;
    font-weight: 600;
    margin-bottom: 20px;
}

.user-reg-frm-wrap h5 {
    font-size: 16px;
    color: #fff;
    margin-bottom: 20px;
}

.form-control {
    border-radius: 3px;
}

.form-select {
    color: #6d6d6d;
    border-radius: 3px;
}

.form-check label {
    color: #fff;
    font-size: 15px;
}

.main-search-form {
    width: 35%;
}


/* ------dropdown----------- */
.login-btn {
    margin-right: 10px;
}

.dropdown-main {
    width: 320px;
    padding: 15px 20px;
}

.drop-user-info {
    margin-bottom: 10px;
}

.account-manage {
    display: block;
    font-size: 14px;
    color: #808080;
    text-align: center;
    margin-bottom: 15px;
}

.user-image-wrap {
    display: block;
    margin-right: 10px;
}

.user-drop-list {
    border-radius: 0px !important;
}

.user-drop-list .list-group-item {
    border: none;
    border-bottom: 1px solid #e9e9e9;
    color: #333;
    padding-bottom: 11px;
}

.bdr-none {
    border: none !important;
}

.user-drop-list .list-group-item i {
    margin-right: 20px;
}

.user-drop-list .list-group-item a {
    font-weight: 600;
}

.user-drop-list .list-group-item a:hover {
    color: #333;
}

.manage-txt {
    color: #333;
    font-weight: normal;
}

.user-image-wrap span {
    display: inline-block;
    width: 45px;
    height: 45px;
    border-radius: 50%;
    border: 2px solid #1dbf73;
    text-align: center;
    line-height: 40px;
    font-size: 18px;
    color: #1dbf73;
}

.user-identity-wrap {
    display: block;
}

.user-identity-wrap h5 {
    font-size: 17px;
    margin-bottom: 5px;
    color: #333;
}

.user-identity-wrap h6 {
    font-size: 14px;
    margin-bottom: 0px;
    color: #808080;
    margin-bottom: 2px;
}


/* ------end dropdown----------- */

/* ---------------------- User registration page end ---------------------- */


/* ---------------------- Provider registration page Start ---------------------- */
.provider-reg-sec {
    width: 100%;
    padding: 0px 0px 100px 0px;
    background-color: #E1FBF9;
}

.provider-reg-wrap {
    width: 60%;
    background-color: #1DBF73;
    background-color: var(--greenColor);
    margin: 0 auto;
    padding: 25px;
    border-radius: 10px;
}

.provider-reg-info {
    width: 100%;
    text-align: center;
    margin-bottom: 35px;
}

.provider-reg-info h3 {
    font-size: 28px;
    font-weight: 600;
    color: #182F46;
    color: var(--headingColor);
}

.height-min {
    min-height: 132px !important;
}

.btn-frm-submit {
    display: inline-block;
    padding: 13px 50px;
    border: none;
    background-color: #FFB602;
    background-color: var(--yellowColor);
    font-weight: 600;
    border-radius: 5px;
}

.lbl-space {
    margin-left: 3px;
    font-size: 14px;
}

.accordion-button,
.accordion-body {
    padding: 10px;
}

.accordion-button:not(.collapsed),
.accordion-button {
    background-color: #E1FBF9;
    color: #182F46;
    font-weight: 600;
    font-size: 15px;
}

.accordion-button:focus {
    box-shadow: none;
}

.accordion-item {
    overflow: hidden;
}


/* Custom check box------ */

.styled-checkbox {
    position: absolute;
    opacity: 0;
}

.styled-checkbox+label {
    position: relative;
    cursor: pointer;
    padding: 0;
}

.styled-checkbox+label:before {
    content: "";
    margin-right: 10px;
    display: inline-block;
    vertical-align: text-top;
    width: 15px;
    height: 15px;
    background: #1DBF73;
    background: var(--greenColor);
    margin-top: 1px;
    border-radius: 2px;
}

.styled-checkbox:focus+label:before {
    box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.12);
}

.styled-checkbox:checked+label:before {
    background: #1DBF73;
    background: var(--greenColor)
}

.styled-checkbox:disabled+label {
    color: #b8b8b8;
    cursor: auto;
}

.styled-checkbox:disabled+label:before {
    box-shadow: none;
    background: #ddd;
}

.styled-checkbox:checked+label:after {
    content: "";
    position: absolute;
    left: 2px;
    top: 10px;
    background: white;
    width: 2px;
    height: 2px;
    box-shadow: 2px 0 0 white, 4px 0 0 white, 4px -2px 0 white, 4px -4px 0 white, 4px -6px 0 white, 4px -8px 0 white;
    transform: rotate(45deg);
}

/* -------------- */
.styled-checkbox-w {
    position: absolute;
    opacity: 0;
}

.styled-checkbox-w+label {
    position: relative;
    cursor: pointer;
    padding: 0;
}

.styled-checkbox-w+label:before {
    content: "";
    margin-right: 10px;
    display: inline-block;
    vertical-align: text-top;
    width: 18px;
    height: 18px;
    background: #fff;
    margin-top: 1px;
    border-radius: 2px;
}

.styled-checkbox-w:focus+label:before {
    box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.12);
}

.styled-checkbox-w:checked+label:before {
    background: #fff;
}

.styled-checkbox-w:disabled+label {
    color: #b8b8b8;
    cursor: auto;
}

.styled-checkbox-w:disabled+label:before {
    box-shadow: none;
    background: #ddd;
}

.styled-checkbox-w:checked+label:after {
    content: "";
    position: absolute;
    left: 4px;
    top: 11px;
    background: #1DBF73;
    background: var(--greenColor);
    width: 2px;
    height: 2px;
    box-shadow: 2px 0 0 #05bb63, 4px 0 0 #05bb63, 4px -2px 0 #05bb63, 4px -4px 0 #05bb63, 4px -6px 0 #05bb63, 4px -8px 0 #05bb63;
    transform: rotate(45deg);
}

/* Custom file upload------- */
.file-lbl {
    background-color: #fff;
    padding: 10px 22px;
    border-radius: 4px;
    cursor: pointer;
    margin-top: 1rem;
    font-size: 14px;
}

#file-chosen {
    margin-left: 0.3rem;
    font-family: sans-serif;
    color: #fff;
}

.file-uploder-wrap {
    margin-bottom: 30px;
}

.lbl-txt {
    font-size: 14px;
}

.bg-white {
    background-color: #fff !important;
}

/* Multi Select Dropdown list container */
.css-3w2yfm-ValueContainer {
    overflow: auto !important;
    max-height: 300px;
}

/* ---------------------- Provider registration page end ---------------------- */

/*provider page start*/

.pro {
    margin: 0px 0px 50px 0px;
    width: 85% !important;
    min-height: 305px !important;
}

.provider h4 {
    font-weight: 700;
    color: #182F46;
    color: var(--headingColor);
    margin: 30px 0 30px 0;
}


.pro-info {
    padding: 10px 24px !important;
    background-color: #FFB602;
}

.pro-info-1 {
    padding: 10px 24px !important;
    background-color: #05BB64;
}

.pro-img {
    height: 100% !important;
}

.pro-rating {
    margin-bottom: 0px !important;
    margin-top: 2px;
}

.pro-info i {
    color: #fff !important;
}

.provider {
    margin-bottom: 50px;
    min-height: 700px;
}

.card-price-1 {
    font-size: 15px !important;
    font-weight: 700 !important;
    color: #fff !important;
}

/*-------------------------provider page end--------------------*/

/*---------------------slot page start-------------------*/
.slot h4 {
    font-weight: 700;
    color: #9C9B9B;
    color: var(--grayTwo);
    margin: 30px 0 30px 0;
}

.sub-hdng {
    margin-bottom: 10px;
    color: var(--gray-one);
    font-size: 14px;
}

.form-control {
    color: #9C9B9B;
    color: var(--grayTwo);
    font-size: 14px;
}

.slot-con {
    display: flex;
    align-items: center;
}

.para-1 {
    font-weight: 700;
    color: #9C9B9B;
    color: var(--grayTwo);
    font-size: 16px;
    margin-bottom: 4px;
}

.para-2 {
    font-weight: 700;
    color: #464646;
    color: var(--paraGray);
    font-size: 16px;
    margin-bottom: 0;
}

.slot-bx {
    width: 85%;
    background-color: #F1F1F1;
    margin-bottom: 20px;
    border-radius: 6px;
    padding: 20px;
    transition: .3s;
}

.slot-bx:hover {
    background-color: #05BB63;
}

.slot-bx a {
    padding: 4px 6px;
    background-color: #A6AAA9;
    border-radius: 50%;
    color: #fff;
}

.icn {
    margin-right: 20px;
}

.slot-bx:hover a {
    background-color: #fff;
    color: #05BB63;
}

.slot-bx:hover .para-2 {
    color: #fff;
}

.slot-bx:hover .para-1 {
    color: #464646;
    color: var(--paraGray);
}

.slot {
    margin-bottom: 50px;
}

.brdr {
    padding-bottom: 30px;
    border-bottom: 1px solid #A6AAA9;
}

.input-group {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    width: 80%;
}

.pro-btn {
    /* margin-top: 50px; */
    margin-bottom: 100px;
}

.pr-btn {
    padding: 12px 30px;
    background-color: #1DBF73;
    background-color: var(--greenColor);
    color: #ffffff;
    color: var(--whiteColor);
    border-radius: 4px;
}


/*---------------------------slot page end---------------------------*/



/*sub-survices page start*/

.sub-survices {
    width: 100%;
    padding-bottom: 100px;
}

.sub-survices-wrap {
    width: 100%;
    padding: 15px 30px;
    background-color: #fff;
    box-shadow: 0 6px 6px #eee;
    border-radius: 5px;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;
    border: 1px solid #ddd;
}

.sub-survices-con-wrap {
    width: 65%;
    align-items: center;
}

.icn-circle {
    display: inline-block;
    width: 36px !important;
    height: 36px;
    border: 2px solid #05BB64;
    border-radius: 50%;
    text-align: center;
    line-height: 30px;
}

.icn-circle .fa-check {
    color: #05BB64;
}

.sub-survices-txt-wrap {
    margin-left: 20px;
}

.sub-survices-txt-wrap h6 {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 5px;
}

.sub-survices-txt-wrap p {
    margin-bottom: 0px;
}

.price {
    display: flex;
    justify-content: space-between;
}

.sub-boxx {
    width: 90%;
    padding: 30px 20px;
    border: 1px solid #adb5bd;
    border-radius: 4px;
}

.prc-para {
    font-size: 16px;
    color: #1dbf73;
    font-weight: 700;
    margin-bottom: 10px !important;
}

.total {
    color: #182F46;
    color: var(--headingColor);
    font-weight: 700;
    font-size: 16px;
}

.mny {
    font-size: 16px;
    color: #FF3103;
    font-weight: 700;
    margin-bottom: 10px !important;

}

.brdr-btm {
    border-bottom: 1px solid #adb5bd;
}

.brdr-top {
    border-top: 1px solid #adb5bd;
}

.m-t {
    padding-top: 10px;
}

.v-align {
    text-align: center;
}

.date-btn {
    width: 100%;
    display: inline-block;
    padding: 10px 0px;
    text-align: center;
    margin-bottom: 40px;
    background-color: #1DBF73 !important;
    background-color: var(--greenColor) !important;
    color: #ffffff !important;
    color: var(--whiteColor) !important;
    border-radius: 4px !important;

}


/*---------------------------END sub-survices-------------------------*/

/*------------Provider profile-------*/

.provider-profle-sec {
    width: 100%;
    padding: 60px 0px;
}

.provider-main-info {
    width: 100%;
}

.provider-main-info h4 {
    font-size: 22px;
    color: #182F46;
    font-weight: 600;
}

.rating-wrap {
    width: 100%;
    margin-top: -5px;
}

.rating-wrap i {
    color: #FFB602
}

.provider-personal-info {
    margin-top: 10px;
}

.provider-personal-info p,
.provider-main-info p {
    color: #737373;
    font-size: 16px;
    margin-right: 40px;
    margin-bottom: 10px;
}

.provider-personal-info p b,
.provider-main-info p b {
    font-weight: normal;
    color: #182F46;
}

.provider-main-info span {
    display: inline-block;
    margin-top: 20px;
}

.provider-rate-table {
    width: 100%;
    padding: 0 0 60px 0px;
}

.tbl-rate {
    width: 100%;
    text-align: center;
}

.tbl-rate thead tr {
    background-color: #1DBF73;
    background-color: var(--greenColor);
    color: #fff;
}

.table-striped>tbody>tr:nth-of-type(odd)>* {
    --bs-table-accent-bg: #fff
}

.table-striped>tbody>tr:nth-of-type(even)>* {
    --bs-table-accent-bg: #E5FFF3
}

.btn-confrm-prov {
    background-color: #018445;
    display: inline-block;
    color: #fff;
    padding: 12px 22px;
    border-radius: 5px;
}

.back-prov {
    background-color: #6d6d6d !important;
}

.provider-all-services {
    width: 100%;
    margin-top: 40px;
}

.btn-post-submit {
    display: inline-block;
    background-color: #05BB63;
    color: #fff;
    border: none;
    padding: 6px 22px;
    margin-left: -5px;
    border-radius: 0 5px 5px 0;
}

.slot h6 {
    font-size: 18px;
    margin-bottom: 9px;
}

/*------------End of Provider profile-------*/

/*------------Slot selection-------*/

.post-cd-search {
    border: none;
    border: 1px solid #ccc;
    padding: 5px 7px;
    border-radius: 5px 0 0 5px;
}

.post-cd-search:focus {
    outline: none;
}

.user-address {
    width: 100%;
    padding: 0 0px 60px 0;
}

.address-box-wrap {
    width: 98%;
    position: relative;
    border: 2px solid #ccc;
    padding: 30px 20px;
    border-radius: 10px;
    min-height: 175px;
}

.add-adress-wrap {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-bottom: 20px;
    overflow: hidden;
}

.add-adress_btn {
    display: inline-block;
    background-color: #05BB63;
    padding: 10px 30px;
    color: #fff;
    border-radius: 5px;
}

.adress_selected {
    /* background-color: #05BB63; */
    background-color: #d2ebdf;
    color: #182F46 !important;
    border: 2px solid #05BB63;
}

.adress_selected .address-name {
    color: #fff !important;
}

.address-name {
    font-size: 22px;
    color: #182F46 !important;
    margin: 0px !important;
    padding-bottom: 10px;
}

.edit-address-btn {
    display: inline-block;
    position: absolute;
    bottom: 10px;
    right: 10px;
    width: 40px;
    height: 40px;
    border: 1px solid #05BB64;
    border-radius: 50%;
    text-align: center;
    line-height: 38px;
}

.edit-address-btn i {
    color: #05BB64;
}

.edit-address-btn i:hover {
    color: #05BB64;
}

.addres-box-hide {
    display: none;
}

/*------------End of Slot selection-------*/

/*------------Date Selection-------*/

.address-name {
    padding-bottom: 0px;
}

.login-time-wrap {
    width: 100%;
    padding: 20px;
    border: 1px solid #ccc;
    text-align: center;
    /* border-radius: 30px; */
    border: 1px solid #05BB64;
    margin-bottom: 20px;
}

.login-time-wrap.selected {
    border: 1px solid #898888;
    background-color: #f9dc94;
}

.login-time-wrap.disabled {
    border: 1px solid #898888;
    background-color: #e7e7e7;
}

.login-time-wrap h6 {
    margin: 0px;
}

.hide-Login-Time-section {
    display: none;
}

/*------------End Date Selection-------*/

/* ----Booking Summary---- */
.box {
    width: 60%;
}

.booking-info-box {
    border: 1px dashed #333;
    margin: 20px 0;
}

.booking-image-hold {
    /* background-image: url('../images/booking-cln-img.jpg'); */
    background-size: cover;
}

.wrapper {
    width: 100%;
    padding: 30px 0px;
}

.box {
    border: 1px solid #ccc;
    overflow: hidden;
}

.border-dott {
    width: 100%;
    border: 1px dotted #333;
}


@media(max-width:1626px) {
    .payment-heading {
        flex: 0 0 auto !important;
        width: 40% !important;
    }

    .payment-details {
        flex: 0 0 auto !important;
        width: 60% !important;
    }
}

@media(max-width:1588px) {
    .text-box h1 {
        font-size: 1.5rem !important;
    }

    .text-box h3 {
        font-size: 1rem !important;
    }
}

@media(max-width:1160px) {
    .payment-heading {
        width: 100% !important;
    }

    .flex-res {
        flex-direction: column !important;
    }

    .wrapper {
        height: auto;
    }

    .box {
        padding: 30px 0;
        height: auto;
    }

    .payment-details {
        width: 100% !important;
    }
}

@media(max-width:914px) {
    .box {
        width: 90vw !important;
    }
}

@media(max-width:914px) {
    td {
        font-size: 12px !important;
    }
}


/* ---- end Booking Summary---- */


/* ---- Order confirmation ---- */

.odr-confrm {
    width: 100%;
}

.odr-confrm-wrap {
    width: 100%;
    padding: 50px 0;
}

.odr-confrm-wrap img {
    margin-bottom: 20px;
}

.odr-confrm-wrap h3 {
    font-weight: 600;
    margin-bottom: 15px;
}

.odr-confrm-wrap a:hover {
    color: #182F46;
}

/* ----End of Order confirmation ---- */

/* ----Booking list ---- */
.booking-list {
    width: 100%;
    padding-bottom: 60px;
}

.booking-list h3 {
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 20px;
}

.btn-cancel {
    width: 145px;
}

.rwd-table {
    width: 100%;
    min-width: 300px;
    max-width: 100%;
    border-collapse: collapse;
}

.rwd-table tr:first-child {
    border-top: none;
    background: #ebf3f9;
    color: #000;
}

.rwd-table tr {
    border-top: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
    background-color: #f5f9fc;
}

.rwd-table tr:nth-child(odd):not(:first-child) {
    background-color: #ebf3f9;
}

.rwd-table th {
    display: none;
}

.rwd-table td {
    display: block;
}

.rwd-table td:first-child {
    margin-top: .5em;
}

.rwd-table td:last-child {
    margin-bottom: .5em;
}

.rwd-table td:before {
    content: attr(data-th) ": ";
    font-weight: bold;
    width: 170px;
    display: inline-block;
    color: #000;
}

.rwd-table th,
.rwd-table td {
    text-align: left;
}

.rwd-table {
    color: #333;
    border-radius: .4em;
    overflow: hidden;
}

.rwd-table tr {
    border-color: #bfbfbf;
}

.rwd-table th,
.rwd-table td {
    padding: .5em 1em;
}

@media screen and (max-width: 601px) {
    .rwd-table tr:nth-child(2) {
        border-top: none;
    }
}

@media screen and (min-width: 600px) {
    .rwd-table tr:hover:not(:first-child) {
        background-color: #d8e7f3;
    }

    .rwd-table td:before {
        display: none;
    }

    .rwd-table th,
    .rwd-table td {
        display: table-cell;
        padding: .25em .5em;
    }

    .rwd-table th:first-child,
    .rwd-table td:first-child {
        padding-left: 0;
    }

    .rwd-table th:last-child,
    .rwd-table td:last-child {
        padding-right: 0;
    }

    .rwd-table th,
    .rwd-table td {
        padding: .25em !important;
        line-height: 40px;
    }
}

/* ----End Booking list ---- */

/* ----Start REACT SLICK ARROW CUSTOM CSS---- */
.slide-address .slick-slider {
    padding: 30px;
    width: 99%;
}

.slick-arrow:before {
    color: #018445 !important;
}

.slick-prev {
    left: 0% !important;
    z-index: 1;
}

.slick-next {
    right: 1% !important;
    z-index: 1;
}

/* ---- End REACT SLICK ARROW CUSTOM CSS ---- */

.policy>ol>li {
    margin-bottom: 20px;
}

.site-container {
    font-size: 17px;
}




/* ---------------------- Responsive CSS Start ---------------------- */
@media (max-width: 1900px) {
    .desktop {
        display: block;
    }

    .mobile {
        display: none;
    }
}

@media (max-width: 1200px) {
    .card-design {
        width: 100%;
    }

    /* .custom-navbar nav,
    .custom-navbar .nav-link-btn {
        display: none;
    } */

    .custom-navbar .nav-btn {
        display: Block;
    }

    .desktop {
        display: block;
    }

    .mobile {
        display: none;
    }
}

@media (max-width: 1024px) {}

@media (max-width: 992px) {

    .services-info {
        text-align: center;
    }

    #services .services-info p {
        width: 100%;
    }

    .marketplace-info {
        width: 100%;
        margin-bottom: 40px;
        text-align: center;
    }

    #marketplace .large-btn,
    #services .large-btn {
        margin: 0 auto;
    }

    .form-wrapper {
        width: 80%;
    }

    .all-services {
        margin-top: 50px;
    }

    .footer-items {
        margin-bottom: 40px;
    }

    .btn-main {
        margin-left: 12px;
        margin-bottom: 25px;
    }

    .banner {
        padding: 150px 0 60px 0px;
    }

    .provider-reg-wrap {
        width: 80%;
    }

}

@media (max-width: 820px) {
    .banner-slider-wrap {
        margin-left: 0px;
    }

    .banner-content {
        margin-bottom: 40px;
        text-align: center;
    }

    .form-wrapper {
        width: 100%;
    }

    .service-item {
        margin-bottom: 30px;
    }

    .user-reg-img-wrap {
        margin-bottom: 30px;
    }

    .provider-reg-wrap {
        width: 100%;
    }
}

@media (max-width: 768px) {
    .banner-link .banner-btn {
        font-size: 13px;
    }

    .form-wrapper {
        width: 100%;
    }

    .input-group {
        position: relative;
        display: flex;
        flex-wrap: wrap;
        align-items: stretch;
        width: 100%;
    }

    .sl-txt {
        width: 40%;
    }

    .sub-para-2 {
        width: 121%;
    }

    .prc-para {
        font-size: 16px;
        color: #1dbf73;
        font-weight: 700;
        margin-bottom: 10px !important;
    }

    .date-btn {
        padding: 10px 15px !important;
        background-color: #1DBF73 !important;
        background-color: var(--greenColor) !important;
        color: #ffffff !important;
        color: var(--whiteColor) !important;
        border-radius: 4px !important;
        font-size: 12px;
    }

    .pro {
        margin: 0px 0px 50px 0px;
        width: 100% !important;
    }

    .slot-bx {
        width: 100%;
    }

    .sub-boxx {
        width: 100%;
    }

    .desktop {
        display: none;
    }

    .mobile {
        display: block;
    }

    .user-reg-img-wrap {
        display: none;
    }



}

@media (max-width: 576px) {
    .search-reasult-wrap {
        flex-direction: column;
    }

    .search-reasult-service {
        width: 100%;
    }

    .search-service-btn {
        width: 100%;
    }

    .sub-survices-wrap {
        padding: 15px;
        flex-direction: column;
    }

    .sub-survices-con-wrap {
        width: 100%;
        margin-bottom: 15px;
    }

    .icn-circle {
        width: 60px;
    }

    .sub-survices {
        padding: 30px 0px;
    }
}

@media (max-width: 480px) {
    .pro-btn {
        /* margin-top: 100px; */
        margin-bottom: 100px;
    }

    .form-control {
        /* margin-bottom: 50px; */
    }

    .sl-txt {
        width: 29%;
    }

    .sub-para-2 {
        width: 136%;
    }

    .pro {
        margin: 0px 0px 50px 0px;
        width: 100% !important;
    }

    .slot-bx {
        width: 100%;
    }
}

@media (max-width: 360px) {
    .custom-navbar {
        padding: 10px 10px;
    }
}

/* ---------------------- Responsive CSS End ---------------------- */

/*------------------------ Google CSS Start-----------------------------*/
body>.skiptranslate {
    display: none;
}

.goog-te-banner-frame.skiptranslate {
    display: none !important;
}

body {
    top: 0px !important;
}

#google_translate_element img {
    display: none;
}

.google-element ul {
    padding: 0px 0 0 15px;
    margin: 0px;
}

.google-element li {
    list-style-type: none;
}

.goog-te-gadget-simple {
    padding: 5px;
    background: none;
    border: 1px solid #666;
    margin-top: 15px;
    border-radius: 5px;
    font-size: 12px;
}


.goog-logo-link {
    display: none !important;
}

.goog-te-gadget {
    color: transparent !important;
}

.goog-te-gadget span {
    display: none;
}

.goog-te-gadget select {
    padding: 5px 10px;
    min-width: 120px;
    background: none;
    border: 1px solid #666;
    margin-top: 25px !important;
    border-radius: 5px;
    font-size: 12px;
}

/*------------------------ Google CSS End-----------------------------*/

/*-------------------------Swipper Styles-----------------------------*/

.swiper-wrapper {
    background-color: #000;
}

.swiper-slide img {
    width: 100%;
}


/*-------------------------Swipper Styles-----------------------------*/

/*===========================ADD CSS 21-12-23==========================*/
.slick-prev:before,
.slick-next:before {
    font-size: 28px;
}

/*====================MY PROFILE PAGE 05-01-2024=================*/

.card {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    /* border: 0 solid transparent; */
    border: 1px solid #ccc;
    border-radius: .25rem;
    margin-bottom: 1.5rem;
    box-shadow: 0 2px 6px 0 rgb(218 218 253 / 65%), 0 2px 6px 0 rgb(206 206 238 / 54%);
    /* margin-top: 0.5rem; */
}

.me-2 {
    margin-right: .5rem !important;
}

.search-icon-wrapper {
    display: inline-block;
    width: 20px;
    margin-left: 50px;
    line-height: 49px;
    float: right;
}

.cart-icon-wrapper {
    display: inline-block;
    width: 15px;
    margin-right: 5px;
    line-height: 49px;
    float: right;
}


/*=================================20-01-2024==============================*/

.vertical-center {
    padding-top: 25px;
}

.align-self-center h2 {
    font-weight: 800;
}

/*=================================22-01-2024=============================*/

.handsontable table thead th {
    background-color: #b8eee9;
    color: #000;
    font-weight: 500;
    font-size: 14px;
}

.handsontable table tbody td {
    color: #000;
    font-size: 14px;
}

.btn-fa-icon {
    line-height: 25px;
}

.user-profile-sec {
    background: #000;
    color: #fff;
    padding: 10px 0;
    border: 1px dashed #ddd;
    border-left: none;
    border-right: none;
}

.user-profile-dtl span {
    display: block;
    line-height: 10px;
    padding: 6px 0 4px 0;
    font-size: 12px;
}
/*===============Mobile navbar=============*/
.custom-navbar-mob {
    width: 90%;
    height: 66px;
    padding: 10px 20px;
    background: rgba(255, 255, 255, 0.77);
    border-radius: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    top: 35px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 999;
    transition: .8s;
}

.custom-navbar-mob.active {
    width: 100%;
    background: rgb(255, 255, 255);
    box-shadow: 0px 10px 25px -7px rgba(0, 0, 0, 0.1);
    border-radius: 0;
    top: 0px;
    z-index: 999;
    transition: .8s;
}

.custom-navbar-mob .brand {
    width: 80%;
}

.custom-navbar-mob .brand a {
    text-decoration: none;
    font-weight: 900;
    font-size: 32px;
    line-height: 38px;
    color: var(--blackColor);
}

.custom-navbar-mob nav ul {
    padding: 0;
    margin: 0;
    list-style: none;
}

.custom-navbar-mob nav ul li {
    display: inline-block;
    padding-inline: 20px;
}

.custom-navbar-mob nav ul li a {
    text-decoration: none;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    color: var(--navColor);
}

.custom-navbar-mob .nav-link-btn {
    display: flex;
    justify-content: right;
    align-items: center;
}


.custom-navbar-mob .join-as {
    /* width: 228px; */
    width: 250px;
    height: 46px;
    background-color: var(--blackColor);
    border-radius: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    margin: 0px 20px 0 5px;
    text-align: center;
}

.custom-navbar-mob .join-as a {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    text-decoration: none;
    color: var(--whiteColor);
}

.custom-navbar-mob .nav-link-btn .login-btn a {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    text-decoration: none;
    color: var(--navColor);
    padding-left: 20px;
    position: relative;
}

.custom-navbar-mob .nav-link-btn .login-btn a span {
    display: inline-block;
    width: 18px;
    height: 18px;
    background-color: #000;
    border-radius: 50%;
    position: absolute;
    top: -12px;
    right: -12px;
    text-align: center;
    font-size: 9px;
    color: #ffffff;
    font-weight: 600;
    line-height: 18px;
}

.custom-navbar-mob .nav-btn-mob {
    display: none;
    font-size: 28px;
    color: var(--blackColor);
    padding: 5px 10px 0px 0px;
}


/* ---------------------- Responsive Navigation CSS Section ----------------------  */
.side-menu {
    width: 300px;
    height: 100%;
    background-color: var(--offWhite);
    position: fixed;
    top: 0;
    left: -300px;
    z-index: 999;
    transition: all 0.5s;
}

.side-menu.active {
    left: 0px;
    transition: all 0.5s;
}

.side-menu .brand {
    padding: 10px 0px 10px 15px;
    background-color: var(--greenColor);
}

.side-menu .brand .close span {
    padding: 1px 10px;
    font-size: 35px;
}


.side-menu .brand a {
    text-decoration: none;
    font-style: normal;
    font-weight: 900;
    font-size: 40px;
    line-height: 38px;
    color: var(--whiteColor);
}

.side-menu .nav-menu {
    height: 60%;
    overflow: auto;
    border-bottom: 1px solid var(--greenColor);
    margin-bottom: 15px;
}

.side-menu .nav-menu ul {
    padding: 0;
    margin: 0;
    list-style: none;
}

.side-menu .nav-menu ul li {
    padding: 10px 0px 10px 15px;
    transition: all 0.5s;
}

.side-menu .nav-menu ul li:hover {
    background-color: #ccc;
    transition: all 0.5s;
}

.side-menu .nav-menu ul li:hover a {
    color: var(--whiteColor);
    transition: all 0.5s;
    color: #000;
}

.side-menu .nav-menu ul li a {
    text-decoration: none;
    font-size: 14px;
    color: var(--blackColor);
}

.side-menu .nav-link-btn {
    text-align: center;
}

.side-menu .nav-link-btn .join-as {
    width: 180px;
    height: 36px;
    background-color: var(--greenColor);
    border-radius: 50px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
}

.side-menu .nav-link-btn .join-as a {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
    text-decoration: none;
    color: var(--whiteColor);
}

.side-menu .join-as a {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    text-decoration: none;
    color: var(--whiteColor);
}

.side-menu .nav-link-btn .login-btn a {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    text-decoration: none;
    color: var(--navColor);
    margin-top: 10px;
    display: block;
}

.content-overlay-open {
    display: block;
    position: fixed;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 7;
    right: 0;
    bottom: 0;
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.7);
}

.content-overlay-close {
    display: none;
}
.search-result-wrap{
    width: 100%;
    position: fixed;
    left: 0px;
    top: 60px;
    overflow-y: scroll;
    overflow-x: hidden;
    background-color: #ffffff;
    z-index: 6;
    padding: 15px;
}

.scrollme{ width:100%; overflow-x: scroll; }

/*======================orientation : landscape=================================*/

@media only screen and (min-device-width: 480px) 
                   and (max-device-width: 640px) 
                   and (orientation: landscape) {

}





/* ---------------------- Responsive Navigation CSS Section ----------------------  */

@media (min-width: 300px) and (max-width: 767.98px) {
    .custom-navbar-mob .nav-btn-mob {display: Block;}
    .nav-menu{ height: 60%;}
    .social-site{ font-size:30px!important;}
    .banner-content form{ display: none;}
    .search-service-btn{ width: 25%; border-radius: 5px;}
    a.search-service-btn:hover { color: #fff; background-color: #05BB64; transition: 0.3s; }
    .user-reg-frm-wrap { width:100%;}
    .user-reg-sec{ margin-bottom: 50px;}    

}

@media (min-width: 300px) and (max-width: 575.98px){
    
}


@media (min-width: 576px) and (max-width: 767.98px) {

    
}


@media (min-width: 768px) and (max-width: 991.98px) {
   
}


@media (min-width: 992px) and (max-width: 1199.98px) {

}


@media (min-width: 1200px) {


}



@media (min-width: 300px) and (max-width: 767.98px) {
    .custom-navbar-mob .nav-btn-mob {display: Block;}
    .nav-menu{ height: 60%;}
    .social-site{ font-size:30px!important;}
    .banner-content form{ display: none;}
    .search-service-btn{ width: 25%; border-radius: 5px;}
    a.search-service-btn:hover { color: #fff; background-color: #05BB64; transition: 0.3s; }
    .user-reg-frm-wrap { width:100%;}
    .user-reg-sec{ margin-bottom: 50px;}    

}

@media (min-width: 300px) and (max-width: 575.98px){
    
}


@media (min-width: 576px) and (max-width: 767.98px) {

    
}


@media (min-width: 768px) and (max-width: 991.98px) {
   
}


@media (min-width: 992px) and (max-width: 1199.98px) {

}


@media (min-width: 1200px) {


}


/*===============Mobile navbar=============*/
.custom-navbar-mob {
    width: 90%;
    height: 66px;
    padding: 10px 20px;
    background: rgba(255, 255, 255, 0.77);
    border-radius: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    top: 35px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 999;
    transition: .8s;
}

.custom-navbar-mob.active {
    width: 100%;
    background: rgb(255, 255, 255);
    box-shadow: 0px 10px 25px -7px rgba(0, 0, 0, 0.1);
    border-radius: 0;
    top: 0px;
    z-index: 999;
    transition: .8s;
}

.custom-navbar-mob .brand {
    width: 80%;
}

.custom-navbar-mob .brand a {
    text-decoration: none;
    font-weight: 900;
    font-size: 32px;
    line-height: 38px;
    color: var(--blackColor);
}

.custom-navbar-mob nav ul {
    padding: 0;
    margin: 0;
    list-style: none;
}

.custom-navbar-mob nav ul li {
    display: inline-block;
    padding-inline: 20px;
}

.custom-navbar-mob nav ul li a {
    text-decoration: none;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    color: var(--navColor);
}

.custom-navbar-mob .nav-link-btn {
    display: flex;
    justify-content: right;
    align-items: center;
}


.custom-navbar-mob .join-as {
    /* width: 228px; */
    width: 250px;
    height: 46px;
    background-color: var(--blackColor);
    border-radius: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    margin: 0px 20px 0 5px;
    text-align: center;
}

.custom-navbar-mob .join-as a {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    text-decoration: none;
    color: var(--whiteColor);
}

.custom-navbar-mob .nav-link-btn .login-btn a {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    text-decoration: none;
    color: var(--navColor);
    padding-left: 20px;
    position: relative;
}

.custom-navbar-mob .nav-link-btn .login-btn a span {
    display: inline-block;
    width: 18px;
    height: 18px;
    background-color: #000;
    border-radius: 50%;
    position: absolute;
    top: -12px;
    right: -12px;
    text-align: center;
    font-size: 9px;
    color: #ffffff;
    font-weight: 600;
    line-height: 18px;
}

.custom-navbar-mob .nav-btn-mob {
    display: none;
    font-size: 28px;
    color: var(--blackColor);
    padding: 5px 10px 0px 0px;
}


/* ---------------------- Responsive Navigation CSS Section ----------------------  */
.side-menu {
    width: 300px;
    height: 100%;
    background-color: var(--offWhite);
    position: fixed;
    top: 0;
    left: -300px;
    z-index: 999;
    transition: all 0.5s;
}

.side-menu.active {
    left: 0px;
    transition: all 0.5s;
}

.side-menu .brand {
    padding: 10px 0px 10px 15px;
    background-color: var(--greenColor);
}

.side-menu .brand .close span {
    padding: 1px 10px;
    font-size: 35px;
}


.side-menu .brand a {
    text-decoration: none;
    font-style: normal;
    font-weight: 900;
    font-size: 40px;
    line-height: 38px;
    color: var(--whiteColor);
}

.side-menu .nav-menu {
    height: 60%;
    overflow: auto;
    border-bottom: 1px solid var(--greenColor);
    margin-bottom: 15px;
}

.side-menu .nav-menu ul {
    padding: 0;
    margin: 0;
    list-style: none;
}

.side-menu .nav-menu ul li {
    padding: 10px 0px 10px 15px;
    transition: all 0.5s;
}

.side-menu .nav-menu ul li:hover {
    background-color: #ccc;
    transition: all 0.5s;
}

.side-menu .nav-menu ul li:hover a {
    color: var(--whiteColor);
    transition: all 0.5s;
    color: #000;
}

.side-menu .nav-menu ul li a {
    text-decoration: none;
    font-size: 14px;
    color: var(--blackColor);
}

.side-menu .nav-link-btn {
    text-align: center;
}

.side-menu .nav-link-btn .join-as {
    width: 180px;
    height: 36px;
    background-color: var(--greenColor);
    border-radius: 50px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
}

.side-menu .nav-link-btn .join-as a {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
    text-decoration: none;
    color: var(--whiteColor);
}

.side-menu .join-as a {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    text-decoration: none;
    color: var(--whiteColor);
}

.side-menu .nav-link-btn .login-btn a {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    text-decoration: none;
    color: var(--navColor);
    margin-top: 10px;
    display: block;
}

.content-overlay-open {
    display: block;
    position: fixed;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 7;
    right: 0;
    bottom: 0;
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.7);
}

.content-overlay-close {
    display: none;
}
.search-result-wrap{
    width: 100%;
    position: fixed;
    left: 0px;
    top: 60px;
    overflow-y: scroll;
    overflow-x: hidden;
    background-color: #ffffff;
    z-index: 6;
    padding: 15px;
}

.scrollme{ width:100%; overflow-x: scroll; }

/*======================orientation : landscape=================================*/

@media only screen and (min-device-width: 480px) 
                   and (max-device-width: 640px) 
                   and (orientation: landscape) {

}





/* ---------------------- Responsive Navigation CSS Section ----------------------  */
